<template lang="pug">
include ../pug/svg
div#orders
	transition(name="fademodal" appear)
		p.no-orders(v-if="!filteredOrders||!filteredOrders.length") 
			span(v-if="filteredOrders===null") Retrieving {{roleOwner?'all':'your'}} orders
			span(v-else) No orders found on your account
			
		div(v-else)
			label.toggle(v-if="roleOwner")
				input(type="checkbox" class="bora" v-model="filterValid")
				span(:class="{unchecked:!filterValid}") Valid
				span(:class="{unchecked:filterValid}") Invalid
			ul#orderlist
				li(v-for="(order, index) in filteredOrders" :index="index" :key="order.id" :class="{cancelled:cancelled(order)||incomplete(order)}")
					div(v-if="roleOwner")
						strong Order ID.
						span {{order.id}} 
					div
						strong Order Ref.
						span(v-if="order.orderRef") {{order.orderRef||'Unavailable'}} 
						span(v-else) Unavailable
					div(:class="{incomplete:incomplete(order)}")
						strong Order Status 
						span(v-if="orderStatus(order)") {{orderStatus(order)}}
					div
						strong Order Date/Time
						span {{formatDate(order.orderDatetime)}} 
					div
						strong Order Total
						Price(:price="order.orderMeta.orderTotal")
					div(v-if="roleOwner")
						strong Customer Email
						a(href="mailto:order.user.email") {{order.user.email}}
					div(v-else)
						strong Order Tracking
						a(v-if="order.fulfilment?.trackingNum" :href="order.fulfilment.trackingUrl" target="blank") {{order.fulfilment.trackingNum}}
						span(v-else-if="order.mintsoftOrderId") Pending
						span(v-else) Unfulfilled
					//-button.outline.text.green(v-if="!order.mintsoftOrderId" type="button" @click="setOrder(order, index)")
					button.outline.text.green(type="button" @click="setOrder(order, index)")
						span View				
	transition(name="fademodal")
		div#order.modal(v-show="viewOrder")
			div
				ul.modal-nav
					li
						button.icon.pulse(type="button" @click="setOrder(null)")
							+svg(svg-filename="iconClose")
							span Close Order
					li
						BasketButton
					//-li(v-if="orders.length>1")
						button.prev.icon.pulse(type="button" @click="prevOrder")
							+svg(svg-filename="iconArrow")
							span Previous Order
					//-li(v-if="orders.length>1")
						button.next.icon.pulse(type="button" @click="nextOrder")
							+svg(svg-filename="iconArrow")
							span Next Order
							
				div.modal-bod(v-if="viewOrder" :class="{swap:swapOrder}")
					div.table
						table
							caption Order Summary
							tr
								th Order Ref.
								td {{viewOrder.orderRef||'Unavailable'}}
							tr(v-if="viewOrder.payment")
								th Payment Status
								td(v-if="viewOrder.payment.status==='requires_payment_method'") Payment Method Required
								td(v-else-if="viewOrder.payment.status==='requires_action'") Payment Confirmation Required 
								td(v-else-if="viewOrder.payment.status==='cancelled'") Payment Cancelled 
								td(v-else-if="viewOrder.payment.status==='succeeded'") Payment Succeeded 
								td(v-else) {{viewOrder.payment.status}}
							tr
								th Order Date
								td {{formatDate(viewOrder.orderDatetime)}}
							tr
								th Order Time
								td {{formatTime(viewOrder.orderDatetime)}}
							tr
								th Delivery Charge
								td &pound; {{viewOrder.orderMeta.deliveryTotal}}
							tr
								th VAT
								td &pound; {{viewOrder.orderMeta.vatTotal}}
							tr
								th Total
								td
									Price(:price="viewOrder.orderMeta.orderTotal")
					div.table
						table
							caption Customer Details
							tr
								th Customer
								td(v-if="viewOrder.fulfilment") {{viewOrder.fulfilment.customer}}
								td(v-else-if="viewOrder.payment") {{viewOrder.payment.customer.firstName}} {{viewOrder.payment.customer.lastName}}
								td(v-else) Unavailable
							tr
								th Email
								td.wb(v-if="roleOwner")
									a(href="mailto:viewOrder.user.email") {{viewOrder.user.email}}
								td.wb(v-else) {{viewOrder.user.email}}
							tr
								th Phone
								td(v-if="viewOrder.fulfilment") {{viewOrder.fulfilment.phone}}
								td(v-else-if="viewOrder.payment") {{viewOrder.payment.customer.phone}}
								td(v-else) Unavailable
							tr
								th Address
								td(v-if="viewOrder.fulfilment")
									span(v-if="viewOrder.fulfilment.company") {{viewOrder.fulfilment.company}}
									span {{viewOrder.fulfilment.address.line1}}
									span(v-if="viewOrder.fulfilment.address.line2") {{viewOrder.fulfilment.address.line2}}
									span(v-if="viewOrder.fulfilment.address.line3") {{viewOrder.fulfilment.address.line3}}
								td(v-else-if="viewOrder.payment")
									span(v-if="viewOrder.payment.customer.company") {{viewOrder.payment.customer.company}}
									span {{viewOrder.payment.customer.address.line1}}
									span(v-if="viewOrder.payment.customer.address.line2") {{viewOrder.payment.customer.address.line2}}
							tr
								th Town
								td(v-if="viewOrder.fulfilment") {{viewOrder.fulfilment.address.town}}
								td(v-else-if="viewOrder.payment") {{viewOrder.payment.customer.address.city}}
							tr
								th County
								td(v-if="viewOrder.fulfilment") {{viewOrder.fulfilment.address.county}}
								td(v-else-if="viewOrder.payment") {{viewOrder.payment.customer.address.state}}
							tr
								th Postcode
								td(v-if="viewOrder.fulfilment") {{viewOrder.fulfilment.address.postcode}}
								td(v-else-if="viewOrder.payment") {{viewOrder.payment.customer.address.postal_code}}
							tr
								th Country
								td(v-if="viewOrder.fulfilment") {{viewOrder.fulfilment.address.country}}
								td(v-else-if="viewOrder.payment") {{viewOrder.payment.customer.address.country}}
					div.table
						table(v-if="viewOrder.fulfilment")
							caption Fulfilment
							tr
								th Order Status
								td {{viewOrder.fulfilment.orderStatus}}
							tr
								th Dispatch Date
								td {{formatDate(viewOrder.fulfilment.dispatchDate)||''}}
							tr
								th Courier Service
								td {{viewOrder.fulfilment.courierService}}
							tr
								th Tracking Number
								td {{viewOrder.fulfilment.trackingNum||'Pending'}}
							tr(v-if="viewOrder.fulfilment.trackingNum")
								th Order Tracking
								td
									a.button.text.outline.green(:href="viewOrder.fulfilment.trackingUrl" target="blank") Track Order
						table(v-else)
							caption Fulfilment
							tr
								th Unavailable
					div.table.owner(v-if="roleOwner")
						h3.caption API Details
						dl
							dt Mintsoft Order ID
							dd
								button.outline.text.green(v-if="user.id===1" type="button" @click="retryFulfilment(viewOrder, true)")
									span Retry - Bad Mintsoft Order Id (LH)
								a(v-if="viewOrder.mintsoftOrderId" :href="'https://om.mintsoft.co.uk/Order/Details/'+viewOrder.mintsoftOrderId" target="_blank") {{viewOrder.mintsoftOrderId}}
								button.outline.text.green(v-else-if="isUnfulfilled" type="button" @click="retryFulfilment(viewOrder, false)")
								span(v-else) Unavailable
							dt Stripe Customer ID
							dd 
								a(v-if="viewOrder.user.stripeCustomerId" :href="'https://dashboard.stripe.com/customers/'+viewOrder.user.stripeCustomerId" target="_blank") {{viewOrder.user.stripeCustomerId}}
								span(v-else) -
							dt Stripe Payment Intent ID
							dd
								a(v-if="viewOrder.stripePaymentIntentId" :href="'https://dashboard.stripe.com/payments/'+viewOrder.stripePaymentIntentId" target="_blank") {{viewOrder.stripePaymentIntentId}}
								span(v-else) -
					div.table.items
						h3.caption Order Items
						ul
							li(v-for="(item, index) in viewOrder.orderMeta.orderItems" :index="index" :key="item.trrId")
								hgroup
									h4 {{item.title}}
									h5 {{item.artistName}}
									h6 {{item.trrSku}} / {{item.catNum}}

								table
									tr
										th Price
										th Qty.
										th Donation
										th Item Total
									tr
										td &pound;{{item.price}}
										td {{item.qty}}
										td &pound;{{item.donation}}
										td
											Price(:price="itemPrice(item)")
</template>

<script>
import AccountService from '../services/AccountService';
import PaymentService from '../services/PaymentService';
import BasketButton from '../components/BasketButton';
import Price from '../components/Price';
import dayjs from 'dayjs';
	
export default {
	name: 'AccountOrders',
	components: {
		BasketButton,
		Price,
	},
	data() {
		return {
			orders: null,
			viewIndex: -1,
			viewOrder: null,
			swapOrder: false,
			filterValid: true,
		};
	},
	created() {
		this.listOrders();
	},
	computed: {
		user() {
			return this.$store.state.auth.user;
		},
		roleOwner() {
			return this.user.roleId === 4;
		},
		filteredOrders() {
			if (this.orders?.length) {
				// filter orders
				if (!this.filterValid) {
					return this.orders.filter(obj => {
						return obj.mintsoftOrderId === null;
					});
					
				} else {
					return this.orders.filter(obj => {
						return obj.mintsoftOrderId !== null;
					});				
				}
				
			} else {
				return this.orders;
			}
		},
		isUnfulfilled() {
			return (this.viewOrder && this.viewOrder.payment.status === 'succeeded' && this.viewOrder.mintsoftOrderId === null);
		},
	},
	methods: {
		handleScroll() {
			console.log(window.scrollY);
		},
		itemPrice(item) {
			const price = (Number(item.qty) * Number(item.price)) + Number(item.donation);
			return price.toFixed(2);
		},
		orderStatus(order) {
			let status;
			
			if (this.cancelled(order)) {
				// cancelled or refunded?
				status = 'Cancelled';				
				
			} else if (this.incomplete(order)) {
				status = 'Incomplete';
				
			} else {
				status = order.fulfilment.orderStatus;				
			}
			
			return status;
		},
		incomplete(order) {
			return !order.mintsoftOrderId;
		},
		cancelled(order) {
			return order.mintsoftOrderId && !order.fulfilment;
		},
		formatDatetime(dateStr) {
            const date = dayjs(dateStr);
            return date.format('DD MMM, YYYY/HH:mm');
		},
		formatDate(dateStr) {
            const date = dayjs(dateStr);
            return date.format('DD MMM, YYYY');
		},
		formatTime(dateStr) {
            const date = dayjs(dateStr);
            return date.format('HH:mm');
		},
		async setOrder(order, index) {
			try {
				if (!order) {
					// reset to default vals
					this.viewIndex = -1,
					this.viewOrder = null;
					
					document.body.classList.remove('modal-order');				
					
				} else {
					if (!order.mintsoftOrderId) {
						// check for payment info
						const {data} = await AccountService.getOrderPayment(order.stripePaymentIntentId);
						order.payment = data.payment;
					}
					
					this.viewIndex = index,				
					this.viewOrder = order;
					
					document.body.classList.add('modal-order');				
				}
				
				this.swapOrder = false;
				
			} catch (err) {
				console.log(err);
			}
		},
		prevOrder() {
			this.swapOrder = true;
			
			let index = this.viewIndex - 1;
			let order = this.orders[index] || null;
			
			if (!order) {
				index = this.orders.length - 1;
				order = this.orders[index];
			}
			
			setTimeout(function() {
				this.setOrder(order, index);			
			}.bind(this), 1000);
		},
		nextOrder() {
			this.swapOrder = true;
			
			let index = this.viewIndex + 1;
			let order = this.orders[index] || null;
			
			if (!order) {
				index = 0;
				order = this.orders[index];
			}
				
			setTimeout(function() {
				this.setOrder(order, index);			
			}.bind(this), 1000);
		},
		async retryFulfilment(order, badMintsoftOrderId) {
			try {				
				// retrieves payment intent/customer data
				let response = await AccountService.getOrderPayment(order.stripePaymentIntentId);
				let paymentIntent = response.data.payment;
				
				console.log( 'paymentIntent:',paymentIntent );
				console.log( 'order.mintsoftOrderId:',order.mintsoftOrderId );
				console.log( 'badMintsoftOrderId:',badMintsoftOrderId );
//				if (this.roleOwner && order.payment.status === 'succeeded' && order.mintsoftOrderId === null) {
				if (this.roleOwner && paymentIntent.status === 'succeeded' && (order.mintsoftOrderId === null || badMintsoftOrderId === true )) {
console.log('AAA');
					// fulfilment failed so manually inititiate it
					const payload = {
						id: paymentIntent.id,
						user: {
							id: order.user.id,
							email: paymentIntent.customer.email,
							customer: {
								firstName: paymentIntent.customer.firstName,
								lastName: paymentIntent.customer.lastName,
								businessName: paymentIntent.customer.company,
								shipping: {
									name: paymentIntent.customer.firstName + ' ' + paymentIntent.customer.lastName,
									phone: paymentIntent.customer.phone,
									address: {
										line1: paymentIntent.customer.address.line1,
										line2: paymentIntent.customer.address.line2,
										city: paymentIntent.customer.address.city,
										state: paymentIntent.customer.address.state,						
										postal_code: paymentIntent.customer.address.postal_code,
										country: paymentIntent.customer.address.country,
									},
								},
							},
						},				
					};
console.log('payload:',payload);
					
					await PaymentService.paymentComplete(payload);
					
					// close order modal
					this.setOrder(null);
					
					// relist orders
					this.listOrders();
					
					// show valid orders
					this.filterValid = true;
					
				} else {
console.log('BBB');
				}
				
			} catch (error) {
				console.log('error', error);
			}			
		},
		async listOrders() {
			try {			
				const {data} = await AccountService.getOrders();
				this.orders = data.orders;
				
			} catch(err) {
				console.log('err:',err);			
			}
		},				
	},
};
</script>

<style lang="scss">
#order {
	.wb {
		word-break: break-all;
	}
	.modal-nav {
		justify-content: flex-end;
		li:first-child {
			margin-right: 0; // remove auto
		}
	}
	.modal-bod {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		margin: auto;
		max-width: 1000px;
		padding-top: 80px;
		border-radius: 4px;
		font-size: 1.4rem;
	}
	h2 {
		width: 100%;
		margin-bottom: 20px;
		text-align: center;
		span {
			color: $green;
		}
	}
	table {
		width: 100%;
		position: relative;
		border-collapse: collapse;
		caption-side: top;
		a {
			color: $green;
		}
	}
	th,
	td {
		position: relative;
		text-align: left;
	}
	th {
		width: 1px;		
	}
	td {
		vertical-align: middle;
		text-align: right;
	}
	dl dt,
	th {
		vertical-align: middle;
		white-space: nowrap;			
		&:not(.caption) {
			font-size: 1rem;
			text-transform: uppercase;
			font-family: 'SansBold';
			color: $grey;
			letter-spacing: .1em;
			padding-right: 20px;
		}
	}
	dl {
		display: flex;
		flex-wrap: wrap;
		a {
			color: $green;
			text-decoration: underline;
			text-decoration-color: transparent;
			transition: .3s ease-in-out;
			&:hover {
				text-decoration-color: $green;					
			}
		}
	}
	dt,
	dd {
		display: inline-flex;
		align-items: center;		
		padding: 5px 0;
		border-top: 1px solid rgba(0,0,0,.1);
	}
	dt {
		padding-top: 5px;
		padding-bottom: 5px;
		width: 180px;
	}
	dd {
		width: calc(100% - 180px);
	}
	.table {		
		width: 100%;
		padding: 10px 15px 5px 15px;
		margin-bottom: 20px;
		border-radius: 4px;
		background: #FFF;
		&.items,
		&.owner {
			width: 100%;
			table {
				th,
				td {
					border: 0 !important;
				}
				th {
//					padding: 0 0 10px 0 !important;
				}
				td {
					//padding: 0 !important;
					&:not(:last-child) {
						padding-right: 20px;
					}
				}
				.price {
					line-height: 0;
				}				
			}
		}
		&.owner {
			td,
			dd { // ids have no spaces
				word-break: break-all;
			}
			button {
				height: 30px;
				line-height: 26px;				
			}
		}		
		&.items {
			ul {
				li {
					display: flex;
					list-style-type: none;
					padding: 10px 0;
					border-top: 1px solid rgba(#000, .1);					
				}
				hgroup {
					flex-grow: 1;
				}
				table {
//					width: auto;
					th,
					td {
						padding-right: 20px;
						white-space: nowrap;
					}
					th {
//						white-space: normal;
					}
				}
			}			
		}
		&:not(.items) {
			table {
				th,
				td {
					padding-top: 5px;
					padding-bottom: 5px;
					border-top: 1px solid rgba(0,0,0,.1);
				}
				th {
//					line-height: 30px;
				}
			}
		}
	}
	caption,
	.caption {
		position: relative;
		text-align: left;
		font-size: 2rem;
		font-weight: normal;
		margin-bottom: 10px;
	}
	.caption {
		width: 100%;
	}
	hgroup {
		h4 {
			font-size: 1.6rem;
			font-weight: normal;
		}
		h5 {
			font-size: 1.4rem;
			font-weight: 500;
			line-height: 1;
			margin-bottom: 5px;
		}
		h6 {
			font-size: 1;
			text-transform: uppercase;
		}
	}
	@media only screen and (max-width: 719px) {
		dl {
			dd {
//				justify-content: flex-end;
			}			
		}
		.table.items {
			ul {
				li {
					flex-wrap: wrap;
				}
			}
			hgroup {
				width: 100%;
				margin-bottom: 20px;
			}	
			td {
				text-align: left;
			}
		}
	}
	@media only screen and (min-width: 720px) {	
		.table {
			&.items {
				ul {
					table {
						width: auto;
					}
				}
			}
		}
	}
	@media only screen and (min-width: 720px) and (max-width: 1023px) {
		.table {
			&:not(.items) {		
				width: calc(50% - 10px);
			}
			&:nth-child(3):nth-last-child(2) {
				// when .owner not displayed
				width: 100% !important;
			}			
		}
		dl {
			dt {
				width: 100%;
				padding-bottom: 0;
				padding-right: 0;
			}
			dd {
				width: 100%;
				border: 0;
			}
		}
	}
	@media only screen and (min-width: 1024px) {
		.table:not(.owner):not(.items) {		
			width: calc(33.33% - 10px);
		}
	}
}
#orders {
//	background: url(/img/order-row.svg) 0 0 repeat-y;
//	background-size: 100% 90px;	
	position: relative;
	min-height: 70px;
	.toggle {
		justify-content: center;		
		margin-bottom: 20px;
		[type=checkbox] ~ span.unchecked {
			color: #999;
		}
	}
/*	&:before {
		z-index: -1;
		content: 'There are no orders on your account.';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 70px;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 10px;
		border: 2px solid #FFF;
		border-radius: 4px;
		text-align: center;
		font-size: 1.8rem;
		color: #FFF;
	}*/
}
#orderlist {
	position: relative;
	>li {
		position: relative;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		min-height: 70px;
		padding: 10px 90px 0 0;
		margin-bottom: 20px;
		background: $white;
		border-radius: 4px;
//		box-shadow: 0 2px 10px rgba(#000, .2);
		&.cancelled {
			>div {
				span,
				strong {
					color: $lightgrey;
				}				
			}
			button {
//				border-color: $lightgrey;
			}
		}
		div {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			align-self: stretch;
			margin:2px 20px 12px 20px;
			&:first-child {
				min-width: 70px;	
			}
			&.incomplete {
				span {
//					color: $error;
				}			
			}
			a,
			span {
				font-size: 1.4rem;
			}
		}
		strong {
			display: block;
			font-size: 1rem;
			text-transform: uppercase;
			font-family: 'SansBold';
			color: $grey;
			letter-spacing: .1em;
		}
		.price {
			line-height: 1;
		}
		a {
			color: $green;
			text-decoration:underline;
			text-decoration-color: transparent;
			&:hover {
				text-decoration-color: $green;
			}
		}
		button {
			position: absolute;
			top: 50%;
			right: 15px;
			transform: translateY(-50%);			
//			margin-left: auto;
//			margin-bottom: 10px;
			span:before {
				box-shadow: none;
			}
		}
	}
}
p.no-orders {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	min-height: 70px;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 10px;
	border: 2px solid #FFF;
	border-radius: 4px;
	text-align: center;
	font-size: 1.8rem;
	color: #FFF;
}
</style>